import { createContext, useState, useContext, useMemo } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import useMobile from './UseMobile';
import DOMPurify from 'dompurify';
import goutteQuestionRed from "../assets/images/goutteQuestionRed.svg";
import { IoCloseOutline } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa6";
import cupSunlight from "../assets/images/cup-sunlight.svg";
import { GoCheckCircle } from "react-icons/go";
import { FaTiktok } from "react-icons/fa";

// Si on est sur une URL set le context sur jeunes || Parent || Relai
// Permet d'éviter lors du rechargement d'avoir un context young par defaut
const initUser = () => {
	const location = window.location.href;
	if (location.includes('Parents') || location.includes('article')) {
		return "parent";
	}
	if (location.includes('/Relai')) {
		return "relai";
	} else {
		return "young";
	}
}

// init context
const UserContext = createContext({ user: initUser, updateUser: () => {} });

// Create provider
export function UserContextProvider({ children }) {
	const [user, setUser] = useState(initUser);
	const [modalQuestionIsOpen, setModalQuestionIsOpen] = useState({
		formModal: false,
		thanksModal: false,
	});

	const value = useMemo(() => ({
		user,
		updateUser: (newUser) => setUser(newUser),
		modalQuestionIsOpen,
		setModalQuestionIsOpen: (newModal) => setModalQuestionIsOpen(newModal),
	  }), [user, modalQuestionIsOpen]);

	const [question, setQuestion] = useState("");
	const isMobile = useMobile();

	// Cette function permet de choisir la couleur de fond des modals
	const chooseBackground = () => {
		if (user === "parent") {
			return "linear-gradient(175deg, #9129ff 7.04%, #E8D9FF 86.23%)";
		} else if (user === "young" && modalQuestionIsOpen.formModal) {
			return "#ffb800";
		} else {
			return "#9129ff";
		}
	};
	const mobileStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			width: "100%",
			height: "100%",
			position: "absolute",
			top: "0",
			left: "0",
			right: "0",
			bottom: "",
			border: "none",
			background: chooseBackground(),
			padding: "20px",
			borderRadius: 0,
			Zindex: 999,
		},
	};
	const desktopStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: window.innerHeight <= 750 ? "100%" : "50%",
			height: window.innerHeight <= 750 ? "100%" : "70%",
			maxWidth: window.innerHeight <= 750 ? "none" : "1000px",
			position: "absolute",
			top: window.innerHeight <= 750 ? "0%" : "15%",
			left:window.innerHeight <= 750 ? "0%" : "25%",
			right: "0",
			bottom: "",
			border: "none",
			background: chooseBackground(),
			padding: "50px",
			borderRadius: window.innerHeight <= 750 ? "0px" : "20px",
			zIndex: 999,
		},
	};
	const axiosConfig = {
		headers: {
			Authorization: `Bearer dd7b07faefc51dd5564f9ded88a99a211e2b2c35425509fa510b0815a68e6fe374ca39f4328457c4d77d289c5ba7563137ad023f57e68f37abe1373c1df4b0d028e5082c9b9ca77f5564b34bc8d54c2da510ecc66ace50d3f8d92d49f780ae8e5e5e3cc3f9c526fc03cb00cbe9ac1be26cc82734f55d65a9f9690187bae1c847`,
		},
	};
	const closeModal = () => {
		setModalQuestionIsOpen({ formModal: false, thanksModal: false });
	};
	const sendQuestion = () => {
		// DomPurify permet de secure les XSS et les injections SQL.
		const sanitizedQuestion = DOMPurify.sanitize(question);
		const data = {
			data: {
				question: sanitizedQuestion,
				answer: "",
				push_first: false,
				publishedAt: null,
				categories: [user === "young" ? 1 : 14]
			},
		};
		axios
			.post(
				"https://admin.parlonsregles.fr/api/testimonies",
				data,
				axiosConfig
			)
			.then(function (response) {
				console.log("response", response);
			})
			.catch(function (error) {
				console.log("error", error);
			});
		setModalQuestionIsOpen({ formModal: false, thanksModal: true });
	};
	return <UserContext.Provider value={value}>
		{children}

		<Modal
				isOpen={modalQuestionIsOpen.formModal}
				onRequestClose={closeModal}
				contentModal="Menu modal"
				contentLabel="Menu Modal"
				shouldCloseOnOverlayClick={true}
				ariaHideApp={false}
				style={isMobile ? mobileStyle : desktopStyle}
			>
				<div className="questionModal">
					<IoCloseOutline
						className="choosePref__modal-close-button"
						onClick={closeModal}
					/>
					<p className="questionModal__title">
						Tu n'as pas trouvé l'infos tu cherchais ? Pose ta
						question
					</p>
					<textarea
						className="questionModal__textarea"
						onChange={(e) => setQuestion(e.target.value)}
						name=""
						placeholder="Pose ta question ici ..."
						id=""
						cols="75"
						rows="5"
					></textarea>
					<div className="questionModal__triangle"></div>
					<img
						className="questionModal__img"
						src={goutteQuestionRed}
						alt="une goutte de sang rouge"
					/>
					<button
						disabled={question.length <= 0}
						className={`focused questionModal__send-button--${
							question.length === 0 ? "inactif" : "actif"
						}`}
						onClick={sendQuestion}
					>
						Envoyer{" "}
						<FaChevronRight className="choosePref__right-chevron" />
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={modalQuestionIsOpen.thanksModal}
				onRequestClose={closeModal}
				contentModal="Menu modal"
				contentLabel="Menu Modal"
				shouldCloseOnOverlayClick={true}
				ariaHideApp={false}
				style={isMobile ? mobileStyle : desktopStyle}
			>
				<IoCloseOutline
					className="choosePref__modal-close-button"
					onClick={closeModal}
				/>
				<div className="thanks-modal">
					<GoCheckCircle  className="thanks-modal__check-img"/>
					<p className="thanks-modal__title">Message bien reçu, on te répond très vite !</p>
					<p className="thanks-modal__text">
						Rejoins nous sur <strong>Tiktok</strong>, on passe en
						revue les nouvelles questions chaque semaines
					</p>
					<a className="thanks-modal__link-tiktok" href="https://www.tiktok.com/@regleselementaires">
						<FaTiktok className="thanks-modal__tiktok" />
					</a>
					<img
						className="choosePref__cup-img"
						src={cupSunlight}
						alt="une cup avec des lunettes de soleil"
					/>
				</div>
			</Modal>
		</UserContext.Provider>;
}

// Custom Hook for interface context (consummer)
export function useUser() {
	return useContext(UserContext);
}
