import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../Hook/useUser';

function NavbarUserFooter() {
	const { user, updateUser } = useUser();
	const update = (value) => {
		updateUser(value);
	};

	return (
		<div className="footer__navbar-user-container">
			<nav className="footer__navbar-user">
				<Link
					className="footer__navbar-user-link focused"
					key={0}
					onClick={() => update('young')}
					to="/"
					style={{ display: user === 'young' ? 'none' : 'block' }}>
					Jeune
				</Link>
				<Link
					className="footer__navbar-user-link focused"
					key={1}
					onClick={() => update('parent')}
					to="/Parents"
					style={{ display: user === 'parent' ? 'none' : 'block' }}>
					Parent
				</Link>
				<Link
					className="footer__navbar-user-link focused"
					key={2}
					onClick={() => update('relai')}
					to="/Relai"
					style={{ display: user === 'relai' ? 'none' : 'block' }}>
					Relais
				</Link>
			</nav>
		</div>
	);
}

export default NavbarUserFooter;
