import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

function Article({ article }) {

  return (
    <Link to={`/article/${article.title.replace(/\s+/g, '-')}/${article.id}`} state={{article: article}} tabIndex="0" className='article focused'>
      <img className="article__header" src={article.header} alt={article.title}></img>
      <div className='article__title-container'>
        <p className="article__title">{article.title}</p>
      </div>
      <div className='article__content-container'>
        {article.category.map((cat) => (
          cat.visible && <span className='article__categories' key={cat.name}>#{cat.name}</span>
        ))}
      </div>
    </Link>
  );
}

Article.propTypes = {
  article: PropTypes.shape({
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

function ArticleContainer({ ListArticles }) {
  return (
    <div className='articleContainer'>
      {ListArticles?.map((articleItem) => (
        <Article key={articleItem.id} article={articleItem} />
      ))}
    </div>
  );
}

ArticleContainer.propTypes = {
  ListArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

export default ArticleContainer;