import React from "react";
import image from "../assets/images/rs-img.svg";
import { FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa";
import bulle from "../assets/images/bulle_reseaux.svg";

function ReseauxSociaux() {
	return (
		<>
			<div className="home-reseaux__bulle">
				<img src={bulle} alt="bulle d'information des reseaux sociaux de parlons règles" />
			</div>
			<div className="home-reseaux">
				<div className="home-reseaux__image-container">
					<img src={image} alt="informative reseaux sociaux" />
				</div>
				<div className="home-reseaux__right-container">
					<div className="home-reseaux__title-container">
						<p className="home-reseaux__title">
							Retrouve pleins d'infos sur nos réseaux
						</p>
					</div>
					<div className="home-reseaux__link-container">
						<a rel="noreferrer" tabIndex="0" className="focused" target="_blank" href="https://www.tiktok.com/@regleselementaires" aria-label="Parlons Règles sur TikTok">
							<FaTiktok className="footer__rs-ico" />
						</a>
						<a rel="noreferrer" tabIndex="0" className="focused" target="_blank" href="https://www.instagram.com/regleselementaires/" aria-label="Parlons Règles sur Instagram">
							<FaInstagram className="footer__rs-ico" />
						</a>
						<a rel="noreferrer" tabIndex="0" className="focused" target="_blank" href="https://www.youtube.com/@ParlonsRegles" aria-label="Parlons Règles sur YouTube">
							<FaYoutube className="footer__rs-ico" />
						</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default ReseauxSociaux;
