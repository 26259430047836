import React from "react";
import atelierRe from "../assets/images/AtelierRE.jpg";
import useMobile from "../Hook/UseMobile";
import { FaChevronRight } from "react-icons/fa6";

function AtelierOrganise() {
	const isMobile = useMobile();

	return (
		<div className="atelier">
			<div className="atelier__text-container">
				<h2 className="atelier__title">
					Organiser{" "}
					<strong>
						<i>un atelier</i>
					</strong>
				</h2>
				<p className="atelier__text">
					Apud has gentes, quarum exordiens initium ab Assyriis ad
					Nili cataractas porrigitur et confinia Blemmyarum, omnes
					pari sorte sunt bellatores seminudi coloratis sagulis pube
					tenus amicti, equorum adiumento
				</p>
				<p className="atelier__text">
					Apud has gentes, quarum exordiens initium ab Assyriis ad
					Nili cataractas porrigitur et confinia Blemmyarum, omnes
					pari sorte sunt bellatores seminudi coloratis sagulis pube
					tenus amicti, equorum adiumento
				</p>
				{!isMobile && (
					<button className="atelier__button focused">
						J'organise un atelier{" "}
						<FaChevronRight className="partners-footer__chevron" />
					</button>
				)}
			</div>
			<div className="atelier__img-container">
				<img className="atelier__img" src={atelierRe} alt="Un professeur qui parle des cycles féminin" />
			</div>
			{isMobile && (
				<button className="atelier__button">
					J'organise un atelier{" "}
					<FaChevronRight className="partners-footer__chevron" />
				</button>
			)}
		</div>
	);
}

export default AtelierOrganise;
