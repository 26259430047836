import React from 'react';
import { v4 as uuidv4 } from 'uuid';


function Video(Video) {
  return (
    <div className='video'>
      <iframe className="video__iframe" src={Video.url} title={Video.title}></iframe>
      <div className='video__description-container'>
        <div className='video__categories-container'>
          {Video.category.map((cat) => (
            cat.visible && <span className='video__categories' key={uuidv4()}>#{cat.name}</span>
          ))}
        </div>
        <h2 className="video__title">{Video.title}</h2>
        <p className="video__desc">{Video.desc}</p>
      </div>
    </div>
  );
} 

function VideoContainer({ListVideo}) {
  return (
    <div className='videoContainer'>
      {/* Ignore chain optional condition */}
      {ListVideo && ListVideo?.map((video) => (
        <Video
          key={uuidv4()}
          id={video.id}
          title={video.title}
          url={video.url}
          desc={video.desc}
          category={video.category}
        />
      ))}
    </div>
  );
}

export default VideoContainer;