import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../Hook/useUser";
import redLogo from "../assets/images/logo-parlons-regles.svg";
import PropTypes from "prop-types";

function NavbarUser({ bColor }) {
	const { user, updateUser } = useUser();

	const update = (value) => {
		updateUser(value);
	};

	return (
		<div
			className="navbar__navbar-user-container"
			style={{ backgroundColor: bColor }}
		>
			<div className="navbar__logo-container">
				<Link
					to={
						user === "young"
							? "/"
							: user === "parent"
							? "/Parents"
							: "/Relai"
					}
				>
					<img
						className="navbar__logo"
						src={redLogo}
						alt="Logo de la barre de navigation"
					/>
				</Link>
			</div>
			<div className="navbar__navbar-user-link-container">
				<nav className="navbar__navbar-user-nav">
					<p
						className="navbar__navbar-who"
					>
						Je suis :
					</p>
					{user !== "young" && (
						<Link
							className="navbar__navbar-user-link focused"
							key={0}
							onClick={() => update("young")}
							to="/"
						>
							Jeune
						</Link>
					)}
					{user !== "parent" && (
						<Link
							className="navbar__navbar-user-link focused"
							key={1}
							onClick={() => update("parent")}
							to="/Parents"
						>
							Parent
						</Link>
					)}
					{user !== "relai" && (
						<Link
							className="navbar__navbar-user-link focused"
							key={2}
							onClick={() => update("relai")}
							to="/Relai"
						>
							Relais
						</Link>
					)}
				</nav>
			</div>
		</div>
	);
}

NavbarUser.propTypes = {
	bColor: PropTypes.string,
};

export default NavbarUser;
