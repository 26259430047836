import React from "react";
import pc1 from "../assets/images/ressource_pc1.svg"
import pc2 from "../assets/images/ressource_pc2.svg"
import book from "../assets/images/ressource_book.svg";
import bulle from "../assets/images/ressource_bulle.svg";

function RessourcesParent() {
    return (
        <div className="ressources">
            <p className="ressources__title">Ressources</p>
            <div className="ressources__grid-container">
                <div className="ressources__top-line">
                    <div className="ressources__square focused" tabIndex="0">
                        <img src={pc1} alt="" aria-hidden="true" />
                        <p>Un site génial que je vous conseille</p>
                    </div>
                    <div className="ressources__square focused" tabIndex="0">
                        <img src={pc2} alt="" aria-hidden="true" />
                        <p>Un site génial que je vous conseille</p>
                    </div>
                </div>
                <div className="ressources__bot-line">
                    <div className="ressources__square focused" tabIndex="0">
                        <img src={book} alt="" aria-hidden="true" />
                        <p>Une brochure à télécharger</p>
                    </div>
                    <div className="ressources__square focused" tabIndex="0">
                        <img src={bulle} alt="" aria-hidden="true" />
                        <p>Des coloriages à télécharger</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RessourcesParent