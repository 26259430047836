import FaqList from "../../components/Faq";
import SendQuestionContainer from "../../components/SendQuestionContainer";
import useAxios from '../../Hook/useAxios';
import React, { useState, useEffect } from "react";
import Filter from "../../components/Filter";
import { orderList } from "../../utilities/Order";
import { SpecificIds } from '../../utilities/SpecificsIdFaq';

function QuestionUser({parent}) {
  const allFaq = useAxios('/testimonies?populate=*');
  const [Faq, setFaq] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const handleFilterChange = (values) => {
    setSelectedValues(values);
  };

  useEffect(() => {
    const getData = () => {
      if (allFaq) {
        const faqItems = allFaq.data.data.map(item => ({
          id: item.id,
          push_first: item.attributes.push_first,
          question: item.attributes.question,
          answer: item.attributes.answer,
          category: item.attributes.categories.data.map(category => ({
            name: category.attributes.name,
            visible: category.attributes.visible
          }))
        }));
        setFaq(faqItems);
      }
    };
    getData();
  }, [allFaq]);

  const orderedFaq = orderList(Faq, selectedValues);
  const filteredFaq = SpecificIds(orderedFaq);
  const FaqDisplay = selectedValues.length > 0 ? orderedFaq : filteredFaq;
  const youngTitle = "Questions <strong><i>les plus posées</i></strong>";
  const parentTitle = "On répond <strong><i>à vos questions</i></strong>";

  return (
    <div className={`QuestionUser ${parent ? "faq-parent" : "faq-young"}`}>
      <div className="QuestionUser__header-container">
        <h1 className="QuestionUser__header-title" style={{color: parent ? "#9129ff" : "#ff224c"}} dangerouslySetInnerHTML={{__html: parent ? parentTitle : youngTitle}}></h1>
        <p className="QuestionUser__header-paragraph" style={{color: parent ? "#9129ff" : "#ff224c"}}>C'est normal d'avoir tout plein de questions au sujet des règles, d'ailleurs il y a sans doute d'autres personnes qui se posent les mêmes. Ici tu pourras trouver pas mal de réponses !</p>
      </div>
      <div className="faqlist" style={parent ? {backgroundColor: "#9129ff"} : {backgroundColor: "#FFB800"}}>
        <div className="faqlist__filter-container">
          <Filter onFilterChange={handleFilterChange} />
        </div>
        <FaqList parent={parent} listFaq={FaqDisplay} loadMore={true} />
      </div>
      <SendQuestionContainer />
    </div>
  );
}

export default QuestionUser;
