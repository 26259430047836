import React, { useState } from "react";
import whiteBook from "../assets/images/white_book.png";
import { FaChevronRight } from "react-icons/fa6";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import useMobile from "../Hook/UseMobile";

function WhiteBookRelai() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [emailSend, setEmailSend] = useState(false);

    const isMobile = useMobile();

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const modalStyleDesktop = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: "50%",
			height: "fit-content",
			top: "25%",
			left: "25%",
			right: "0",
			bottom: "",
			border: "none",
			background:
				"linear-gradient(175deg, #007AFF 7.04%, #00B2FF 66.23%)",
			padding: "50px",
			borderRadius: "20px",
			zIndex: 999,
		},
	};

    const modalStyleMobile = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: "100%",
			height: "100%",
			top: "0",
			left: "0",
			right: "0",
			bottom: "",
			border: "none",
			background:
				"linear-gradient(175deg, #007AFF 7.04%, #00B2FF 66.23%)",
			padding: "10px",
			borderRadius: "0px",
			zIndex: 999,
		},
	};

	const submitEmail = (e) => {
		const axiosConfig = {
			headers: {
				Authorization: `Bearer dd7b07faefc51dd5564f9ded88a99a211e2b2c35425509fa510b0815a68e6fe374ca39f4328457c4d77d289c5ba7563137ad023f57e68f37abe1373c1df4b0d028e5082c9b9ca77f5564b34bc8d54c2da510ecc66ace50d3f8d92d49f780ae8e5e5e3cc3f9c526fc03cb00cbe9ac1be26cc82734f55d65a9f9690187bae1c847`,
			},
		};

		e.preventDefault();
		const data = {
			data: {
				email: e.target[0].value,
			},
		};
		axios
			.post(
				"https://admin.parlonsregles.fr/api/contacts",
				data,
				axiosConfig
			)
			.then(function (response) {
				console.log("response", response);
				setEmailSend(true);
			})
			.catch(function (error) {
				console.log("error", error);
			});
	};

	return (
		<div className="whiteBook">
			<div className="whiteBook__left-container">
				<h2 className="whiteBook__title">
					Accompagner les élèves <br />
					<strong>
						<i>sur Parlons Règles</i>
					</strong>
				</h2>
				<p className="whiteBook__text">
					Apud has gentes, quarum exordiens initium ab Assyriis ad
					Nili cataractas porrigitur et confinia Blemmyarum, omnes
					pari sorte sunt bellatores seminudi coloratis sagulis pube
					tenus amicti, equorum adiumento
				</p>
				<p className="whiteBook__text">
					pud has gentes, quarum exordiens initium ab Assyriis ad Nili
					cataractas porrigitur et confinia Blemmyarum, omnes pari
					sorte sunt bellatores seminudi coloratis sagulis pube tenus
					amicti, equorum adiumento...
				</p>
			</div>
			<div className="whiteBook__right-container">
				<div className="whiteBook__img-container">
					<img className="whiteBook__img" src={whiteBook} alt="pictogramme d'un livres" />
				</div>
				<button
					className="whiteBook__download-button focused"
					onClick={() => setModalIsOpen(true)}
				>
					Télécharger le livre blanc{" "}
					<FaChevronRight className="partners-footer__chevron" />
				</button>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					contentModal="Menu modal"
					contentLabel="Menu Modal"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					style={isMobile ? modalStyleMobile : modalStyleDesktop}
				>
					<div className="whiteBook__modal-content-container">
						{!emailSend && (
							<>
                            		<IoCloseOutline
									className="choosePref__modal-close-button"
									onClick={() => setModalIsOpen(false)}
								/>
								<p className="whiteBook__modal-title">
									Pour télécharger le livre blanc merci de
									bien vouloir indiquer votre adresse e-mail
								</p>
								<form
									className="whiteBook__form"
									action=""
									onSubmit={(e) => submitEmail(e)}
								>
									<input
										className="whiteBook__email-input"
										type="email"
										placeholder="E-mail"
									/>
									<button
										className="whiteBook__form-button"
										type="submit"
									>
										Envoyer{" "}
										<FaChevronRight className="partners-footer__chevron" />
									</button>
								</form>
							</>
						)}
						{emailSend && (
							<>
								<IoCloseOutline
									className="choosePref__modal-close-button"
									onClick={() => setModalIsOpen(false)}
								/>
								<p>Lien de téléchargement du livre blanc</p>
							</>
						)}
					</div>
				</Modal>
			</div>
		</div>
	);
}

export default WhiteBookRelai;
