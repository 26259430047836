import { useState, useEffect } from "react";
import useAxios from "../Hook/useAxios";
import crossIco from "../assets/images/right-arrow.svg";
import { FaChevronRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import ChoosePreferenceButton from "./ChoosePreferenceButton";
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from 'react-swipeable';

function SwapVideo() {
	const [index, setIndex] = useState(0);
	const [dataVideo, setDataVideo] = useState(null);
	const [visible, setVisible] = useState(true);
	const AllVideo = useAxios("/videos?populate=*");
	const navigate = useNavigate();

	const goToAllOnPeriodsWithTag = (value) => {
		navigate("/Jeunes/ToutSurLesRegles", { state: { tag: value} });
	};

	const nextVideo = () => {
		dataVideo !== null && index === dataVideo.length - 1
			? setIndex(0)
			: setIndex(index + 1);
		setVisible(false);
		setTimeout(() => {
			setVisible(true);
		}, "500");
	};

	useEffect(() => {
		const getDataVideo = () => {
			if (AllVideo) {
				const videos = AllVideo.data.data.map((video) => video);
				setDataVideo(videos);
			}
		};
		getDataVideo();
	}, [AllVideo]);

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			nextVideo();
		}
	}

	const handleSwipe = () => {
		nextVideo();
	}

	const swipeHandlers = useSwipeable({
		onSwipedLeft: handleSwipe,
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
		delta: 50
	  });


	return (
		<div className="swapVideo">
			<div className="swapVideo__aside-container">
				<div>
					<h2 className="swapVideo__title">
						Tout sur les <br />
						<strong>
							<i> règles</i>
						</strong>
					</h2>
					<p className="swapVideo__text">
						Les règles ce n'est ni mystérieux, ni tabou, c'est
						complètement naturel ! Ici on en parle sans jugement, et
						on se dit tout !
					</p>
				</div>
				<Link to="/Jeunes/ToutSurLesRegles" className="focused">
					<button className="swapVideo__aside-button" type="button">
						Voir toutes les vidéos{" "}
						<FaChevronRight className="swapVideo__right-chevron" />
					</button>
				</Link>
				<div className="swapVideo__aside-pref-button-container">
					<ChoosePreferenceButton />
				</div>
			</div>
			{dataVideo && (
				<div className="swapVideo__card-container letter">
					<AnimatePresence>
						{visible && (
							<motion.div
								{...swipeHandlers}
								initial={{opacity: 0}}
								animate={{
									opacity: 1,
									x: 0,
									rotate: 0,

								}}
								exit={{opacity: 0, x: -500, rotate: -25}}
								transition={{ duration: .4, type: 'bounce'}}
								className="swapVideo__iframe-container"
							>
								<iframe
									className="swapVideo__iframe"
									width="100%"
									height="315"
									src={dataVideo[index].attributes.url}
									title="YouTube video player"
									allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								></iframe>
								<div className="swapVideo__info-container">
									<p className="swapVideo__video-title">
										{dataVideo[index].attributes.title}
									</p>
									{dataVideo[index].attributes.categories.data.map(
										(tag) => (
											<button
												onClick={() => goToAllOnPeriodsWithTag(tag.attributes.name)}
												key={tag.id}
												className="swapVideo__video-tag focused"
												style={{
													display: tag.attributes.visible
														? "inline-block"
														: "none",
												}}
											>
												#{tag.attributes.name}
											</button>
										)
									)}
									<p className="swapVideo__description">
										{dataVideo[index].attributes.description}
									</p>
									<div
										onKeyDown={(e) => handleKeyDown(e)} role="button"
										tabIndex="0"
										className="swapVideo__button-container"
									>
										<img
											onClick={nextVideo}
											className="swapVideo__cross-ico"
											src={crossIco}
											alt="prochaine vidéo"
										/>
										<p className="swapVideo__next-button focused">
											Voir une autre vidéo
										</p>
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
			<Link to="/Jeunes/ToutSurLesRegles">
				<button className="swapVideo__mobile-button" type="button">
					Voir toutes les vidéos{" "}
					<FaChevronRight className="swapVideo__right-chevron-mobile" />
				</button>
			</Link>
		</div>
	);
}

export default SwapVideo;
