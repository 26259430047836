import React, { useState } from "react";
import NavbarUser from "./NavbarUser.jsx";
import { Link } from "react-router-dom";
import { useUser } from "../Hook/useUser.jsx";
import redLogo from "../assets/images/logo-parlons-regles.svg";
import blueLogo from "../assets/images/BlueLogo.svg";
import HappyCup from "../assets/images/HappyCup.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";

function NavbarHeader() {
	const { user, setModalQuestionIsOpen } = useUser();
	const [modalIsOpen, setModalIsOpen] = useState(false);

	function getNav() {
		switch (user) {
			default:
				return (
					// Liens pour l'utilisateur "Jeunes"
					<nav className="navbar__red-nav">
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Jeunes/ToutSurLesRegles"
						>
							Vidéos sur les règles
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Jeunes/QuestionsUtilisateurs"
						>
							questions les plus posées
						</Link>
						<p
							onClick={() =>
								setModalQuestionIsOpen({
									formModal: true,
									thanksModal: false,
								})
							}
							className="navbar__link focused"
						>
							{/* link for keyboard navigation */}
							<a href="#" className="focused">
								je pose ma question
							</a>
						</p>
					</nav>
				);
			case "parent":
				return (
					// Liens pour l'utilisateur "Parents"
					<nav className="navbar__blue-nav">
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Parents/AllArticles"
						>
							Le blog des règles
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Parents/QuestionsUtilisateurs"
						>
							On répond à vos questions
						</Link>
						{/* Link for keyboard navigation */}
						<a href="#"
							onClick={() =>
								setModalQuestionIsOpen({
									formModal: true,
									thanksModal: false,
								})
							}
							className="navbar__link focused"
						>
							poser une question
						</a>
					</nav>
				);
			case "relai":
				return (
					// Liens pour l'utilisateur "Relai"
					<nav className="navbar__blue-nav">
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Relai/Blog"
						>
							Le blog des règles
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Relai/Atelier"
						>
							Organiser un atelier
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Relai/Ressources"
						>
							Ressources
						</Link>
					</nav>
				);
		}
	}

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	return (
		<div className="navbar">
			<NavbarUser />
			<div className="navbar__navbar-container">{getNav()}</div>
			<div className="modal__show-modal">
				<Link
					className="focused"
					to={
						user === "young"
							? "/"
							: user === "parent"
							? "/Parents"
							: "/Relai"
					}
				>
					<img
						className="modal__show-modal-logo"
						src={user === "young" ? redLogo : blueLogo}
						alt="Logo de la barre de navigation"
					/>
				</Link>
				<RxHamburgerMenu
					onClick={openModal}
					className="modal__burger-ico"
					style={{ color: user === "young" ? "#9129FF" : "#007AFF" }}
				/>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentModal="Menu modal"
				contentLabel="Menu Modal"
				shouldCloseOnOverlayClick={true}
				ariaHideApp={false}
				style={{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.7)",
						zIndex: "999",

					},
					content: {
						width: "100%",
						height: "100%",
						position: "absolute",
						top: "0",
						left: "0",
						right: "0",
						bottom: "0",
						border: "none",
						background: "white",
						padding: 0,
						borderRadius: 0,
						Zindex: "999",
					},
				}}
			>
				<>
					<div className="modal__header-container">
						<Link
							className="focused"
							to={
								user === "young"
									? "/"
									: user === "parent"
									? "/Parents"
									: "/Relai"
							}
						>
							<img
								className="modal__show-modal-logo focused"
								src={user === "young" ? redLogo : blueLogo}
								alt="Logo de la barre de navigation"
							/>
						</Link>
						<IoCloseOutline
							className="modal__close-button"
							style={{
								color: user === "young" ? "#9129FF" : "#007AFF",
							}}
							onClick={closeModal}
						/>
					</div>
					<div className="modal__navbar-container-modal">
						<div className="modal__navbar-modal">{getNav()}</div>
					</div>
					<div className="modal__navbar-user-container">
						<img
							className="modal__happy-cup"
							src={HappyCup}
							alt="Logo happy cup"
						/>
						<NavbarUser
							closeModal={closeModal}
							bColor={user === "young" ? "#FFB800" : "#00B2FF"}
						/>
					</div>
				</>
			</Modal>
		</div>
	);
}

export default NavbarHeader;
