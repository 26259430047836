import { useState, useEffect, React } from "react";
import useAxios from "../Hook/useAxios";
import { usePref } from "../Hook/usePref";
import Modal from "react-modal";
import { FaChevronRight } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import cupSunlight from "../assets/images/cup-sunlight.svg";
import { Link } from "react-router-dom";
import useMobile from "../Hook/UseMobile";

function ChoosePreferenceButton() {
	const isMobile = useMobile();
	const [modalState, setModalState] = useState({
		stepOneIsOpen: false,
		stepTwoIsOpen: false,
	});
	const [tags, setTags] = useState(null);
	const fetchCategoriesRequest = useAxios("/categories");
	const { pref, updatePref } = usePref();
	// Period a trois valeur en string : true, false, notYet
	const [period, setPeriod] = useState(
		pref.period === "false"
			? "false"
			: pref.period === "true"
			? "true"
			: "notYet"
	);

	useEffect(() => {
		if (fetchCategoriesRequest) {
			const allTags = fetchCategoriesRequest.data.data.map((tag) => tag);
			setTags(allTags);
		}
	}, [fetchCategoriesRequest]);

	// mobile = 100% de l'ecran, Desktop = 50% center
	const mobileStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			width: "100%",
			height: "100%",
			position: "absolute",
			top: "0",
			left: "0",
			right: "0",
			bottom: "",
			border: "none",
			background: "#9129FF",
			padding: "20px",
			borderRadius: 0,
			Zindex: 999,
		},
	};
	const desktopStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: window.innerHeight <= 750 ? "100%" : "50%",
			height: window.innerHeight <= 750 ? "100%" : "50%",
			position: "absolute",
			minHeight: window.innerHeight <= 750 ? "0" : "570px",
			top: window.innerHeight <= 750 ? "0" : "25%",
			left: window.innerHeight <= 750 ? "0" : "25%",
			right: "0",
			bottom: "",
			border: "none",
			background: "#9129FF",
			padding: "50px",
			borderRadius: window.innerHeight <= 750 ? "none" : "20px",
			zIndex: 999,
		},
	};

	// Gestion des ouvertures/fermetures des modals
	const openStepOne = () => {
		setModalState({ stepOneIsOpen: true, stepTwoIsOpen: false });
	};
	const closeStepOne = () => {
		setModalState({ stepOneIsOpen: false, stepTwoIsOpen: false });
	};
	const closeStepTwo = () => {
		setModalState({ stepOneIsOpen: false, stepTwoIsOpen: false });
	};
	const openStepTwo = () => {
		setModalState({ stepOneIsOpen: false, stepTwoIsOpen: true });
	};

	// Verifie sur le tag est déjà présent dans le localStorage, renvoie un bool
	const tagIsActiv = (value) => {
		return pref.tags.find((prefTag) => (prefTag === value));
	};

	const saveLocalStorageTag = (tag) => {
		const prefTags = pref.tags;
		if (tagIsActiv(tag)) {
			const newTags = prefTags.filter((item) => item !== tag);
			const newData = {
				age: pref.age,
				period: pref.period,
				tags: newTags,
			};
			updatePref(newData);
		} else {
			prefTags.push(tag);
			const newData = {
				age: pref.age,
				period: pref.period,
				tags: prefTags,
			};
			updatePref(newData);
		}
	};

	const saveUserAge = (age) => {
		const newData = { age: age, period: pref.period, tags: pref.tags };
		updatePref(newData);
	};

	const savePeriod = (string) => {
		setPeriod(string);
		const newData = { age: pref.age, period: string, tags: pref.tags };
		updatePref(newData);
	};

	// Genère la liste du dropdown de selection d'age
	const ageSelectOptions = [];
	for (let i = 6; i <= 18; i++) {
		ageSelectOptions.push(
			<option key={i} value={i}>
				{/* Pour l'affichage du 18 + on ajoute une string '+' si i === 18 */}
				{i === 18 ? i + "+" : i}
			</option>
		);
	}

	return (
		<div className="choosePref">
			<button
				className="choosePref__button-choose-pref focused"
				onClick={openStepOne}
			>
				Choisir les sujets qui m'intéressent{" "}
				<FaChevronRight className="choosePref__right-chevron" />
			</button>
			{modalState.stepOneIsOpen && (
				<Modal
					isOpen={modalState.stepOneIsOpen}
					onRequestClose={closeStepOne}
					contentModal="Menu modal"
					contentLabel="Menu Modal"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					style={isMobile ? mobileStyle : desktopStyle}
				>
					<p className="choosePref__step1-title">
						Choisis les sujets qui t'interessent
					</p>
					<IoCloseOutline
						className="choosePref__modal-close-button"
						onClick={closeStepOne}
					/>
					<div className="choosePref__tag-container">
						{tags.map((tag) => {
							return (
								<button
									tabIndex="0"
									type="button"
									style={{
										display: tag.attributes.visible
											? ""
											: "none",
									}}
									onClick={() =>
										saveLocalStorageTag(tag.attributes.name)
									}
									className={`focused ${
										tagIsActiv(tag.attributes.name)
											? "choosePref__tag--actif"
											: "choosePref__tag"
									}`}
									key={tag.attributes.id}
								>
									{tag.attributes.name}
								</button>
							);
						})}
					</div>
					<div className="choosePref__next-button-container">
						<button
							className="choosePref__next-button focused"
							onClick={openStepTwo}
						>
							Suivant{" "}
							<FaChevronRight className="choosePref__right-chevron" />
						</button>
					</div>
					<img
						className="choosePref__cup-img"
						src={cupSunlight}
						alt="Logo cup"
					/>
				</Modal>
			)}
			{modalState.stepTwoIsOpen && (
				<Modal
					isOpen={modalState.stepTwoIsOpen}
					onRequestClose={closeStepTwo}
					contentModal="Menu modal"
					contentLabel="Menu Modal"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					style={isMobile ? mobileStyle : desktopStyle}
				>
					<IoCloseOutline
						className="choosePref__modal-close-button"
						onClick={closeStepTwo}
					/>
					<p className="choosePref__step2-title">Quel âge as-tu ?</p>
					<select 
						className="choosePref__select"
						value={pref.age}
						onChange={(e) => saveUserAge(e.target.value)}
					>
						{ageSelectOptions}
					</select>
					<div className="choosePref__checkbox-container">
						<p className="choosePref__step2-title">As tu tes règles ?</p>
						<div className="choosePref__checkbox-line">
							<input
								checked={period === "true"}
								value={"true"}
								type="checkbox"
								onChange={(e) => savePeriod(e.target.value)}
								/>
								<label>oui</label>
						</div>
						<div className="choosePref__checkbox-line">
							<input
								checked={period === "false"}
								value={"false"}
								type="checkbox"
								onChange={(e) => savePeriod(e.target.value)}
								/>
								<label>non</label>
						</div>
						<div className="choosePref__checkbox-line">
							<input
								checked={period === "notYet"}
								value={"notYet"}
								type="checkbox"
								onChange={(e) => savePeriod(e.target.value)}
								/>
								<label>Pas encore</label>
						</div>
					</div>
					<div>
						<div className="choosePref__next-button-container">
							<Link to="/Jeunes/ToutSurLesRegles">
								<button className="choosePref__next-button focused">Voir les vidéos <FaChevronRight className="choosePref__right-chevron" /></button>
							</Link>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
}

export default ChoosePreferenceButton;
