import AtelierOrganise from "../../components/AtelierOrganise";
import HeaderParent from "../../components/HeaderParent";
import PartnersFooter from "../../components/PartnersFooter";
import WhiteBookRelai from "../../components/WhiteBookRelai";

function HomeRelay() {
    return (
      <div className="HomeRelay">
          <HeaderParent />
          <WhiteBookRelai />
          <AtelierOrganise />
          <PartnersFooter />
      </div>
    );
  }

export default HomeRelay;