import React from "react";
import logoMobile from "../assets/images/parent-head-logo.svg";
import logo from "../assets/images/bulle_header_parents.svg";
import { useNavigate } from "react-router-dom";
import { useUser } from '../Hook/useUser';


function HeaderParent() {
	const navigate = useNavigate();
	const { user } = useUser();

	const goToAllArticleWithTag = (value) => {
		if (user === "parent") {
			navigate("/Parents/AllArticles", { state: { tag: value} });
		} else {
			navigate("/Relai/Blog", { state: { tag: value} });
		}		
	};

	return (
		<div className="headerParent">
			<div className="headerParent__left-container">
                <img className="headerParent__desktop-img" src={logo} alt="Logo d'une goutte de sang" />
                <p className="headerParent__left-container-text">
                    Saraceni tamen nec amici nobis umquam nec hostes
                    optandi, ultro citroque discursantes quicquid inveniri
                    poterat momento temporis parvi vastabant milvorum
                    rapacium similes, qui si praedam dispexerint celsius,
                    volatu rapiunt celer.
                </p>
			</div>
			<div className="headerParent__left-container-mobile">
				<div className="headerParent__title-container">
					<h1 className="headerParent__title1">
						Enfin un site pour savoir{" "}
					</h1>
					<h1 className="headerParent__title2">de quoi on parle</h1>
				</div>
				<div className="headerParent__logo-container">
					<img src={logoMobile} alt="Logo mobile" />
				</div>
			</div>
			<div className="headerParent__video-container">
				<iframe
					className="headerParent__iframe"
					width="100%"
					height=""
					src="https://www.youtube.com/embed/XwIC5C0WVJg?si=QTkgxVuhjpCToXii"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				></iframe>
				<div className="headerParent__sub-video-container">
					<div className="headerParent__tag-container">
						<button className="headerParent__tag focused" onClick={() => goToAllArticleWithTag("Tabou")}>#Tabou</button>
					</div>
					<p className="headerParent__video-title">Parler des règles en famille</p>
				</div>
			</div>
		</div>
	);
}

export default HeaderParent;
