import React from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import imgMobile from "../assets/images/bulle_home_mobile.svg";
import imgDestkop from "../assets/images/bulle_home_desktop.svg";
import ChoosePreferenceButton from "./ChoosePreferenceButton";
import useMobile from "../Hook/UseMobile";

function YoungHeader() {
	const navigate = useNavigate();
    const isMobile = useMobile();

	const goToResult = (value) => {
		navigate("/Jeunes/Resultats", { state: { userInput: value } });
	};

	const goToAllOnPeriodsWithTag = (value) => {
		navigate("/Jeunes/ToutSurLesRegles", { state: { tag: value} });
	};

	return (
		<div className="young-head">
			<div className="young-head__searchbar-container">
				<img className="young-head__img" src={isMobile ? imgMobile : imgDestkop} alt="Une cup qui parle" />
				<SearchBar />
                {!isMobile &&(
                    <div className="young-head__question-desktop-container">
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Est-ce que c’est normal d’avoir mal pendant ses règles")}>Est-ce que c’est normal d’avoir mal pendant ses règles ?</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Comment faire du sport quand on a ses règles")}>Comment faire du sport quand on a ses règles ?</button>
                        <button tabIndex="0" className="young-head__question-desktop focused" onClick={() => goToResult("Pourquoi je n’ai pas encore mes règles")}>Pourquoi je n’ai pas encore mes règles ?</button>
                    </div>
                )}
			</div>
			<div className="young-head__video-container">
				<iframe
					className="young-head__video"
					width="560"
					height="315"
					src="https://www.youtube.com/embed/PpnJh-LZFMw?si=cm0RSxhrMXK_5ybn"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				></iframe>
				<div className="young-head__video-desc-container">
					<p className="young-head__video-title">
					« Les règles, c’est sale » : un cliché vieux comme l’histoire
					</p>
					<div className="young-head__tag-list-container">
						<button type="button" className="young-head__tag focused" onClick={() => goToAllOnPeriodsWithTag("Tabou")}>#Tabou</button>
					</div>
				</div>
			</div>
			{isMobile && (
				<div className="young-head__most-question-container">
					<div>
						<p className="young-head__most-question-title">Questions<br /> les plus <strong>posées</strong></p>
						<p className="young-head__question-mobile" onClick={() => goToResult("Est-ce que c’est normal d’avoir mal pendant ses règles")}>
							Est-ce que c’est normal d’avoir mal pendant ses règles ?
						</p>
						<p className="young-head__question-mobile" onClick={() => goToResult("Comment faire du sport quand on a ses règles")}>
							Comment faire du sport quand on a ses règles ?
						</p>
						<p className="young-head__question-mobile" onClick={() => goToResult("Pourquoi je n’ai pas encore mes règles")}>
							Pourquoi je n’ai pas encore mes règles ?
						</p>
					</div>
					<div className="young-head__pref-button-container">
						<ChoosePreferenceButton />
					</div>
				</div>
			)}
		</div>
	);
}

export default YoungHeader;
