import React from "react";
import youngMobileLogo from "../assets/images/young_mobile_sendQuestion.svg";
import youngDesktopLogo from "../assets/images/young_desktop_sendQuestion.svg";
import parentMobileLogo from "../assets/images/parents_mobile_sendQuestion.svg";
import parentDesktopLogo from "../assets/images/parents_desktop_sendQuestion.svg";
import { useUser } from "../Hook/useUser";
import RatingModal from "./RatingModal";
import useMobile from "../Hook/UseMobile";

function SendQuestionContainer() {
	const { user, setModalQuestionIsOpen } = useUser();
	const isMobile = useMobile();

	const chooseLogo = () => {
		if (isMobile) {
			return user === "young" ? youngMobileLogo : parentMobileLogo;
		} else {
			return user === "young" ? youngDesktopLogo : parentDesktopLogo;
		}
	};

	return (
		<div className="send-question">
            <RatingModal />
			<img className="send-question__img" src={chooseLogo()} alt="logo parlons règles" />
			<button
				className="send-question-button"
				onClick={() =>
					setModalQuestionIsOpen({
						formModal: true,
						thanksModal: false,
					})
				}
			>
				Je pose ma question
			</button>
		</div>
	);
}

export default SendQuestionContainer;
